var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        { attrs: { dense: "", color: "primary", dark: "", height: "36" } },
        [
          _c(
            "v-toolbar-title",
            { staticClass: "my-0 py-0 text-subtitle-2" },
            [
              _c("v-icon", { staticClass: "mr-2" }, [
                _vm._v("mdi-message-text")
              ]),
              _vm._v(" " + _vm._s(_vm.message.user.name) + " "),
              _c(
                "v-chip",
                {
                  attrs: {
                    color: _vm.colorFromStatus(_vm.message.status),
                    small: ""
                  }
                },
                [_vm._v(_vm._s(_vm.message.status))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { tile: "", height: "150" } },
        [
          _c("v-card-text", [
            _c("h5", [_vm._v(_vm._s(_vm.subtitle))]),
            _vm.message.contact
              ? _c("h5", [
                  _vm._v(
                    _vm._s(_vm.message.contact.name) +
                      " | " +
                      _vm._s(_vm.message.contact.type)
                  )
                ])
              : _vm._e(),
            _c("p", [_vm._v(_vm._s(_vm.messageData))])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }