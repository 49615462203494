<template>
    <div>
        <v-toolbar dense color="primary" dark height="36">
            <v-toolbar-title class="my-0 py-0 text-subtitle-2">
                <v-icon class="mr-2">mdi-message-text</v-icon>
                {{message.user.name}}
                <v-chip :color="colorFromStatus(message.status)" small>{{message.status}}</v-chip>
            </v-toolbar-title>
        </v-toolbar>
        <v-card tile height="150">
            <v-card-text>
                <h5>{{subtitle}}</h5>
                <h5 v-if="message.contact">{{message.contact.name}} | {{message.contact.type}}</h5>
                <p>{{messageData}}</p>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
const MAX_MESSAGE_LENGTH = 200;
export default {
    name: "message-detail",
    components: {},
    props: {
        message: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            messageData: this.truncate(this.message),
        };
    },
    computed: {
        subtitle() {
            const phoneNumber =
                this.message.status == "received"
                    ? this.message.from_formatted
                    : this.message.to_formatted;
            return `${this.message.created_at} | ${phoneNumber}`;
        },
    },
    watch: {},
    detroyed() {},
    methods: {
        colorFromStatus(status) {
            switch (status) {
                case "received":
                    return "success";
                case "failed":
                    return "error";
                case "delivered":
                case "sent":
                    return "info";
            }
        },
        truncate(message) {
            return message.Body.length > MAX_MESSAGE_LENGTH
                ? `${message.Body.substr(0, MAX_MESSAGE_LENGTH)}...`
                : message.Body;
        },
    },
};
</script>

<style scoped>
</style>